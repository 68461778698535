
.homeContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.homeContent p {
    font-size: 1.25rem;
}

.homeArea {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 100vh;
}

.homeArea p {
    text-align: center;
}

.homeArea h1 {
    text-align: center;
}