.socialNetworkContent{
    display: flex;
    flex-direction: row;
}

.socialItem a{
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #f2f2f2;
    display: flex;
    color: #f2f2f2;
    transition: all 0.4s ease-out;
}
.socialItem a:hover{
    color: #00BA7C;
    border-color: #00BA7C;
}