.content {
  overflow: hidden;
  padding-left: 300px !important;
}

.content-full {
  padding-left: 0 !important;
  
}

.contentSection {
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  width: 100%;
}



.contentTitle {
  position: relative;
  margin:0;
  padding: 0;
  margin-left: 50px;
  margin-top: 20px;
}

.app {
  display: flex;
  position:relative;
  
}


#root {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #15202be1;
  color: #f2f2f2;
  font-size: 18px;
}

.app main{
  
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}


.sidebar-left {
  left: -300px !important;
}

.sidebar{
  
  height: 100vh;
  width: 300px;
  min-width: 300px;
  overflow-y: auto;
  overflow: hidden;
  position:fixed;
  left: 0;
  top: 0;
  
  z-index:1009;
}

.sidebar-inner {
  background-color: #15202B;
  height: 100%;
  min-width: 300px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

h1,h2,h3,h4{
  color: #f2f2f2;
  font-family: montserrat-medium, sans-serif;
}

h2 {
  font-size: 2.75rem;
}

h3{
  font-size: 2rem;
}

h4 {
  font-size: 1.50rem;
}

.colorTheme {
  color: #00BA7C
}

.sidebar-content {
  width: 100%;
  margin-bottom: 0;
}

.sidebarList {
  height: auto;
  width: auto;
}

.sidebarList .row {
  margin: 5px 10px;
  height: 60px;
  width: auto;
  list-style-type: none;

  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-transform: uppercase;
  box-shadow: inset 0 0 0 0 #00BA7C70;
  -webkit-transition: all ease-in-out 0.4s;
	-moz-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
}

.sidebarList .row:hover{
  color: #00BA7C;

  box-shadow: inset 300px 0 0 0 #00BA7C70;
}



.sidebarList #active {
  color: inherit;
  background-color: #00BA7C;
}


.layout {
  height: 100vh;
  padding: 0;
  margin: 0;
  width: 100vw;
  flex-direction: row;
}


.iconMenu{
  z-index: 1100;
  position: fixed;
  left: 300px;
  top: 50px;
  border: 1px solid #2e344e;
  background-color: #15202be1;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}

.menuTranistion{
  transition: ease-in-out 0.5s;
}

.iconMenu-left{
  left: 0px ;
}

.iconMenu-hide{
  visibility: hidden ;
  transition: none;
}
